import React, { Dispatch, SetStateAction, useContext, useState } from 'react';

import * as S from './styles';

import CloseIcon from '@mui/icons-material/Close';
import FieldFormik from '../../../../../../../../FieldFormik';
import { AlertWarning } from '../../../../../../../../Alert';
import InfoListAcademicProject from '../InfoListAcademicProject';
import { ButtonSimple } from '../../../../../../../../Buttons';
import { myCoursesServiceNewStudent } from '../../../../../../../../../../services/newStudent';
import { changeObjectToFormData } from '../../../../../../../../../../services/api/newStudent/apiStudent.service';
import {
  IRulesToSend,
  IShowInternship,
} from '../../../../../../../../../../services/newStudent/myCourses/myCourses.service';
import { ISubjectContentData } from '../../../../../..';
import { IClassInfo } from '../../../../../../../../../../pages/newStudent/MyCoursesNewStudent/components/Subjects';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../../../../ProviderNewStudent';
import { BasicDialog } from '../../../../../../../../Dialog';
import { IconButton } from '@mui/material';
import {
  invoiceIugu,
  creditCardIugu,
  pixIugu,
} from '../../../../../../../../../../variables';
import { Formik } from 'formik';

export interface IFile {
  file: File | undefined;
  name: string;
}
interface Props {
  academicProjectData?: IShowInternship;
  name?: string;
  setWhoShow: Dispatch<SetStateAction<'register' | 'historic' | 'list'>>;
  setLoadAcademicProjectData: Dispatch<SetStateAction<boolean>>;
  info: ISubjectContentData;
  classInfo: IClassInfo;
  isTcc?: boolean;
  canShowPayment?: boolean;
  options?: {
    dismissalTcc?: boolean;
    finalCorrectionTcc?: boolean;
    orientationTcc?: boolean;
    overview?: string[];
  };
  tccObservationDismissal?: string[];
}
export default function RegisterAcademicProject({
  academicProjectData,
  name,
  setWhoShow,
  setLoadAcademicProjectData,
  info,
  classInfo,
  isTcc,
  options,
  tccObservationDismissal,
  canShowPayment,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;

  const [titleTcc, setTitleTcc] = useState<string>('');
  const [rules, setRules] = useState<IRulesToSend>();

  const [textObservation, setTextObservation] = useState('');
  const [reeded, setReaded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [type, setType] = useState(0);

  const [fileTcc, setFileTcc] = useState<IFile>({
    file: undefined,
    name: '',
  });

  const [finalReport, setFinalReport] = useState<IFile>({
    file: undefined,
    name: '',
  });

  const [mandatoryAttachment, setMandatoryAttachment] = useState<IFile>({
    file: undefined,
    name: '',
  });

  const [paymentType, setPaymentType] = useState<number | string>(0);

  const handleSend = async () => {
    try {
      setIsLoading(true);

      const objectChanged = changeObjectToFormData({
        id: classInfo.courseId,
        disciplineId: classInfo.id,
        contentId: info.contentId,
        resourceId: info.resourceId ?? 0,
        academicProjectId: academicProjectData?.academicProjectId ?? null,
        subject: !isTcc
          ? academicProjectData?.subject ?? 'Estágio Supervisionado'
          : titleTcc,
        observation: textObservation,
        status: type,
        paymentType: canShowPayment ? paymentType : null,
        file: type !== 3 || !isTcc ? fileTcc.file : null,
        finalReport: type !== 3 || !isTcc ? finalReport.file : null,
        mandatoryAttachment:
          type !== 3 || !isTcc ? mandatoryAttachment.file : null,
      });

      const { result, data } =
        await myCoursesServiceNewStudent.saveNewHistoricInternship(
          objectChanged,
        );
      if (result) {
        if (type === 3 && data !== true) {
          window.open(String(data));
        }
        setWhoShow('historic');
        setLoadAcademicProjectData(true);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderRules = async () => {
    try {
      setIsLoading(true);
      const { result, data } = await myCoursesServiceNewStudent.resourceRules({
        resourceId: info.resourceId,
        type: isTcc ? 1 : type === 0 ? 3 : type,
      });
      if (result) {
        setIsModalOpen(true);
        setRules(data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <S.Container>
      <S.SelectType>
        <S.DivKeySelectType>
          <S.Key>Selecione o tipo: </S.Key>
        </S.DivKeySelectType>
        {isTcc ? (
          (options?.orientationTcc ||
            options?.finalCorrectionTcc ||
            options?.dismissalTcc) && (
            <S.DivCheckbox>
              {options?.orientationTcc && (
                <FieldFormik
                  name="mentoring"
                  onChange={() => {
                    setType(1);
                  }}
                  type="checkbox"
                  placeholder="Tutoria / Orientação"
                  widthSize="fullWidth"
                  checked={type === 1}
                  notLimitHeight
                />
              )}
              {options?.finalCorrectionTcc && (
                <FieldFormik
                  name="finalReportTcc"
                  onChange={() => {
                    setType(2);
                  }}
                  type="checkbox"
                  placeholder="Correção Final"
                  widthSize="fullWidth"
                  checked={type === 2}
                  notLimitHeight
                />
              )}
              {options?.dismissalTcc && (
                <FieldFormik
                  name="dismissTcc"
                  onChange={() => {
                    setType(3);
                  }}
                  type="checkbox"
                  placeholder="Dispensa"
                  widthSize="fullWidth"
                  checked={type === 3}
                  notLimitHeight
                />
              )}
            </S.DivCheckbox>
          )
        ) : (
          <S.DivCheckbox>
            <S.DivFinalReport>
              <FieldFormik
                name="finalReport"
                onChange={() => {
                  setType(3);
                }}
                type="checkbox"
                placeholder="Análise de Documentação e Relatório Final"
                widthSize="fullWidth"
                checked={type === 3}
                notLimitHeight
              />
            </S.DivFinalReport>
            <S.DivDismissInternship>
              <FieldFormik
                name="dismissInternship"
                onChange={() => {
                  setType(8);
                }}
                type="checkbox"
                placeholder="Dispensa do Estágio Supervisionado"
                widthSize="fullWidth"
                checked={type === 8}
                notLimitHeight
              />
            </S.DivDismissInternship>
          </S.DivCheckbox>
        )}
      </S.SelectType>
      <S.DivWarning>
        {options?.overview &&
          options?.overview?.map((item, index) => (
            <S.TextOverView key={index}>{item}</S.TextOverView>
          ))}
      </S.DivWarning>
      {type !== 3 ? (
        <AlertWarning
          title={
            <h6>
              Antes de enviar, leia atentamente as{' '}
              {isTcc ? (
                <S.A onClick={renderRules}>Regras de envio do TCC.</S.A>
              ) : (
                <S.A onClick={renderRules}>
                  Regras de {type === 8 ? 'Dispensa' : 'Envio'} do Estágio.
                </S.A>
              )}
            </h6>
          }
        />
      ) : (
        <S.DivWarning>
          {tccObservationDismissal?.map((item, index) => (
            <S.TextWarning key={index}>{item}</S.TextWarning>
          ))}
        </S.DivWarning>
      )}
      <InfoListAcademicProject
        student={name}
        date={new Date().toLocaleDateString()}
        subject={academicProjectData?.subject}
        setTextObservation={setTextObservation}
        textObservation={textObservation}
        finalReport={finalReport}
        setFinalReport={setFinalReport}
        mandatoryAttachment={mandatoryAttachment}
        setMandatoryAttachment={setMandatoryAttachment}
        fileTcc={fileTcc}
        setFileTcc={setFileTcc}
        isTcc={isTcc}
        setTitleTcc={setTitleTcc}
        type={type}
      />
      {type === 3 && canShowPayment && (
        <Formik
          initialValues={{
            selectPaymentType: undefined,
            selectOptionsParcels: undefined,
          }}
          onSubmit={() => {}}
          enableReinitialize
        >
          {({ values }) => (
            <S.DivSelect>
              <S.H6>Forma de Pagamento: </S.H6>
              <FieldFormik
                name="selectPaymentType"
                type="select"
                value={values.selectPaymentType}
                onChange={(e) => {
                  setPaymentType(+e.target.value);
                }}
                options={[
                  {
                    title: '[ Selecione a forma de pagamento ]',
                    value: undefined,
                  },
                  { title: 'BOLETO', value: Number(invoiceIugu) },
                  { title: 'CARTÃO', value: Number(creditCardIugu) },
                  { title: 'PIX', value: Number(pixIugu) },
                ]}
                widthSize="xxlarge"
              />
            </S.DivSelect>
          )}
        </Formik>
      )}
      <S.ButtonSave>
        <S.DivReaded>
          <input
            name="reeded"
            onChange={({ target }) => setReaded(target.checked)}
            type="checkbox"
          />
          <h6>
            Li e concordo com as{' '}
            <S.A onClick={renderRules}>
              Regras de {isTcc ? 'envio.' : type === 8 ? 'Dispensa' : 'Envio'}
            </S.A>
          </h6>
        </S.DivReaded>
        <S.Buttons>
          {academicProjectData && (
            <ButtonSimple
              color="primary"
              outline
              onClick={() => setWhoShow('list')}
              widthSize="small"
            >
              Voltar
            </ButtonSimple>
          )}
          <ButtonSimple
            color="success"
            widthSize="large"
            font="h5"
            disabled={!reeded}
            onClick={handleSend}
          >
            Salvar
          </ButtonSimple>
        </S.Buttons>
      </S.ButtonSave>
      {rules && (
        <BasicDialog isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
          <S.DialogComponent>
            <S.DialogTitleDiv>
              <div>
                <S.DialogTitle>{rules.title}</S.DialogTitle>
                {rules.information && <S.Value>{rules.information}</S.Value>}
              </div>
              <IconButton onClick={() => setIsModalOpen(false)}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </S.DialogTitleDiv>
            {rules.structure.map((item, index) => (
              <S.KeyValueDialog key={index}>
                <S.Key>{item.title}</S.Key>
                {item.text.map((text, i) => (
                  <S.Value key={index + '_' + i}>{text}</S.Value>
                ))}
              </S.KeyValueDialog>
            ))}
          </S.DialogComponent>
        </BasicDialog>
      )}
    </S.Container>
  );
}
